
import { useTranslation } from 'react-i18next';
import { getLocal } from '../commons/utils'
import './results.css';
export default function ResultScreen(props) {
    var message;
    var wordLabel; 
    const { t, i18n } =  useTranslation();
    const history = [];

    if (props.isWin) {
        message = "Maita'i!";
        wordLabel = t('wordGuessed')
    } else {
        message = "'aita!"
        wordLabel = t('wordNotGuessed')
    }


    for(var i=0;i< props.stats.history.length; i++) {
        const squares = []
        for(var j = 0; j<  props.stats.history[i].attempts; j++) {
            squares.push(<div key={"sq_"+j} className='square-indicator'>&nbsp;</div>)
        }
        history.push(<div key={"psq_"+i}> ({i+1}){squares}</div>)
    }

    return <div className='result-container'>
        <h1>{message}</h1>
        <div className='stats-container'>
            {props.isWin ? history : null}
        </div>
        <p className='world-label'>{wordLabel}:</p>
        <p className="result-word">{props.word.word}</p>
        <p className='world-label'>{t('thatMeans')}</p>
        <p className="word-definition">{props.word.translation[getLocal(i18n.language)]}</p>
        <div>
            <button className='buttonOk' onClick={()=>props.onResultClose()}>{t('accept')}</button>
        </div>
    </div>
}