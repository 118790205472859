import Wordle from "../components/wordle/wordle";
import logoImg from '../images/head_logo_small.png';
import {sameDate, tomorrowAtZero} from "../commons/utils";
import CountDownScreen from "./countDownScreen";
import AboutModal from "./aboutModal";
import './wordleScreen.css';
import '../commons/w3.css';
import LanguageSelector from "../components/languageSelector/languageSelector";
import {v4 as uuidv4} from 'uuid';

export default function WordleScreen(props) {
    const myStats = loadPlayerData();

    var mainContent;
    if (sameDate(myStats.lastGameDate, new Date())) {
        mainContent = <CountDownScreen date={tomorrowAtZero()}></CountDownScreen>
    } else {
        mainContent = <Wordle attempts={props.attempts} word={props.word} myStats={myStats}></Wordle>
    }
    return <div><center>
        <div className="header">
            <img src={logoImg} width="45px" height="44px" />&nbsp;
            <div className="title">Wordle Reo Tahiti</div>&nbsp;
            <img src={logoImg} width="45px" height="44px" />
            <div className="buttonsRight">
                <AboutModal />
            </div>
            <div><LanguageSelector></LanguageSelector></div>
            
        </div>
        <div className="board">
           {mainContent}
        </div>
        </center>
    </div>;
}

function loadPlayerData() {
    var myStatsStr = localStorage.getItem('myStats');
    var myStats;
    if(myStatsStr === undefined || myStatsStr == null) {
        //console.log('my stats not defined')
        myStats = {
            playerId : uuidv4(),
            lastGameDate : null,
            history : [{attempts:0},{attempts:0},{attempts:0},{attempts:0},{attempts:0},{attempts:0}]
        }
        localStorage.setItem('myStats', JSON.stringify(myStats));
    } else {
        //console.log('myStats: '+myStatsStr)
        myStats = JSON.parse(myStatsStr);
        myStats.lastGameDate = new Date(myStats.lastGameDate);
    }
    return myStats;
}
