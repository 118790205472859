import { useTranslation } from 'react-i18next';
import CountDown from '../components/countDown/countDown';
export default function CountDownScreen(props) {
    const { t } =  useTranslation();

    return <div>
        <p className='cds-greeting'>{t("congrats")}</p>
        <p className='cds-next-message'>{t("waitNextMessage")}</p>
        <CountDown date={props.date}></CountDown>
    </div>
}