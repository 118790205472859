export function getLocal(language) {
    return language.substring(0,2);
}

export function sameDate(date1, date2) {
    console.log(date1);
    console.log(date2);
    console.log(date1 != null && date2 != null && date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate());
    return date1 != null && date2 != null && date1.getFullYear() === date2.getFullYear()
        && date1.getMonth() === date2.getMonth()
        && date1.getDate() === date2.getDate();
}

export function tomorrowAtZero() {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return new Date(now.getTime()+86400000);
};