import './keyboard.css';
import  '../wordle/wordle.css';
import { BsBackspaceFill } from "react-icons/bs";
import { AiOutlineEnter } from "react-icons/ai";
export default function Keyboard(props) {
    const chars = ['\'','a','e','i','o','u','ā','ē','ī','ō','ū','f','h','m','n','p','r','t','v'];
    const letterButtons = [];
    chars.forEach((letter, index, array) => {
        const letterObject = props.lettersSelected.find(letterObj => letter == letterObj.value);
        const letterClass = typeof letterObject === "undefined" ? "" : letterObject.className
        letterButtons.push(<button key={"letterId"+index} className={"letterButton kbButton "+letterClass} onClick={() => props.onClickLetter(letter)}>{letter}</button>);
    })
    letterButtons.push(<button key={"letterBTBack"} className="kbEnter kbButton" onClick={() => props.onClickBackSpace()}><BsBackspaceFill /></button>);
    letterButtons.push(<button key={"letterBTEnter"} className="kbEnter kbButton" onClick={() => props.onClickEnter()}><AiOutlineEnter/></button>);
    return <div className="kb-container">
            {letterButtons}
    </div>;
}