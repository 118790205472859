import { useState, useEffect } from "react";
export default function CountDown(props) {
    const date = props.date;
    var timeLeftseconds;
    const toTwoDigitsString = (number) => number < 10 ? "0"+number : number;
     
    const [timeLeftString, setTimeLeftString] = useState("");


    useEffect(() => {
        const intervalId = setInterval(()=>{
            timeLeftseconds = Math.floor((date.getTime() - new Date().getTime())/1000);
            var d={};
            d.hours = Math.floor(timeLeftseconds/(60*60));
            timeLeftseconds -= d.hours*60*60;
    
            d.minutes = Math.floor(timeLeftseconds/60);
            timeLeftseconds -= d.minutes*60;
        
            d.seconds = timeLeftseconds;
            d.formatedTime = toTwoDigitsString(d.hours)+":"+toTwoDigitsString(d.minutes)+":"+toTwoDigitsString(d.seconds);
            setTimeLeftString(d.formatedTime);
        },1000);

        return () => clearInterval(intervalId);
    })
    

    return <div>
        <div className="count-down-counter">{timeLeftString}</div>
    </div>;
}