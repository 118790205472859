import React from "react";
import './wordle.css';
import Keyboard from "../keyboard/keyboard";
import {Icon} from '@mui/material';
import ResultScreen from "../../screens/resultScreen";
import { useTranslation, withTranslation } from 'react-i18next';
import WordsService from '../../services/wordsServiceLocal';

const STAGE_GAME = 'GAME';
const STAGE_RESULT = "RESULT";

class Wordle extends React.Component {
    constructor(props) {
        super(props);
        this.service = new WordsService();
        this.state = {
            stage:STAGE_GAME,
            currentAttempt:0,
            letterIndex:0,
            definition:props.definition,
            word:props.word,
            wordLength:props.word.word.length,
            attempts:props.attempts,
            isWin: true,
            attemptWords: this.initializeAttemptWord(props.attempts, props.word.word.length),
            stats: props.myStats,
            lettersSelected:[],
            tmpIcon :props.word.hintIconName
        }
        this.onClickLetter = this.onClickLetter.bind(this);
        this.onClickBackSpace = this.onClickBackSpace.bind(this);
        this.onClickEnter = this.onClickEnter.bind(this);
        this.onResultClose = this.onResultClose.bind(this);
    }

    
    initializeAttemptWord(attempts_num, wordLength) {
        const attempts = [];
        for(var i=0;i<attempts_num;i++) {
            attempts[i] = [];
            for(var j=0;j<wordLength;j++){
                attempts[i][j] = {value:'', className:''};
            }
        }
        return attempts;
    }

    onResultClose() {
        this.setState({stage:STAGE_GAME});
    }
    render() {
        switch(this.state.stage) {
            case STAGE_GAME:
                return this.renderBoard();
            case STAGE_RESULT:
                return this.renderResult();
            default:
                return <div></div>;
        }
    }


    renderResult() {
        return <ResultScreen 
                word={this.state.word} 
                isWin={this.state.isWin}
                attempts={this.state.currentAttempt}
                stats={this.state.stats}
                onResultClose={this.onResultClose}>
            </ResultScreen>
    }

    renderBoard() {
        const rows = [];
        const word = this.state.word.word.split('');
        for(var i=0;i<this.state.attempts;i++) {
            const cells = [];
            var ic = 0;
            for(const letter of word) {
                cells.push(<td key={"cell"+ic} className={"boardCell "+this.state.attemptWords[i][ic].className}>{this.state.attemptWords[i][ic].value}</td>)
                ic++;
            }
            rows.push(<tr key={"row"+i}  className="boardRow">{cells}</tr>)
        }
        return <div>
            <div>{this.props.t('hint')}: <Icon fontSize="large">{this.state.tmpIcon}</Icon></div>
            <table className="tableBoard">
                <tbody>
                {rows}
                </tbody>
            </table>
            <div>
              <Keyboard 
                onClickLetter={this.onClickLetter}
                onClickBackSpace={this.onClickBackSpace}
                onClickEnter={this.onClickEnter}
                lettersSelected={this.state.lettersSelected}  >
              </Keyboard>
              <div>{this.props.t('aboutp4')}</div>
            </div>
        </div>
    }

    onClickLetter(letter) {
        let letterIndex = this.state.letterIndex;
        if(letterIndex < this.state.wordLength) {
            const words = this.state.attemptWords;
            words[this.state.currentAttempt][letterIndex].value = letter;
            letterIndex++;
            this.setState({attemptWords:words,letterIndex:letterIndex});
        }
    }

    onClickBackSpace() {
        let letterIndex = this.state.letterIndex;
        if(letterIndex > 0) {
            letterIndex--;
        }
        const words = this.state.attemptWords;
        words[this.state.currentAttempt][letterIndex]={value:'', className:''};
        this.setState({attemptWords:words,letterIndex:letterIndex});
    }

    onClickEnter() {
        let letterIndex = this.state.letterIndex;
        let currentAttempt = this.state.currentAttempt;
        if(letterIndex === this.state.wordLength){
            let win = this.evaluateWord();
            currentAttempt++;
            this.service.registerActivity(this.state.stats, win, currentAttempt);
            letterIndex=0;
            this.setState({currentAttempt:currentAttempt, letterIndex:letterIndex});
        }
        
    }

    evaluateWord() {
        const words = this.state.attemptWords;
        const realWord = this.state.word.word.split('');
        const guessWord = words[this.state.currentAttempt].map(element => element.value);
        const lettersSelected = this.state.lettersSelected;
        let newStage = this.state.stage;
        let resultClass = '';
        let isWin = false;
        let currentAttempt = this.state.currentAttempt;
        let lettersCorrect = 0;
        let charObject = realWord.reduce((accumulator, currentValue) => {
            if (currentValue in accumulator) {
                accumulator[currentValue]++;
            } else {
                accumulator[currentValue] = 1;
            }
            return accumulator;
        }, {});

        guessWord.forEach((letter, index, array) => {
            if(letter === realWord[index]){
                resultClass = 'good';
                lettersCorrect++;
                charObject[letter]--; 
            } else if(realWord.reduce((tot,cur)=>tot+cur).indexOf(letter) !== -1) {
                resultClass = 'almost';
            } else {
                resultClass = 'wrong';
            }
            lettersSelected.push({value:letter, className:resultClass});
            words[this.state.currentAttempt][index].className = resultClass;
        });

        guessWord.forEach((letter, index, array) => {
            resultClass = 'wrong';
            if(lettersSelected[index].className === 'almost' && charObject[letter] == 0) {
                lettersSelected[index].className = resultClass;
                words[this.state.currentAttempt][index].className = resultClass
            }  
        });

        currentAttempt++;
        isWin = lettersCorrect == this.state.word.word.length;
        var stats = this.state.stats;
        if(isWin || currentAttempt >= this.state.attempts ) {
            newStage = STAGE_RESULT;
            if(isWin) {
                stats = this.registerWin();
            }
            currentAttempt = 0;
        }
        this.setState({attemptWords:words, stage: newStage, isWin: isWin, stats:stats, lettersSelected:lettersSelected});
        return isWin;
    }

    componentDidMount() {
        
    }

    registerWin(){
       // console.log(this.state.stats);
       const stats = this.state.stats;
       const winDate = new Date();
       stats.totalPlayed = stats.totalPlayed + 1;
       stats.lastGameDate = winDate;
       stats.history[this.state.currentAttempt] = {attempts:(stats.history[this.state.currentAttempt].attempts + 1), winDate:winDate, word:this.state.word.word};
       this.setState({stats:stats});
       localStorage.setItem('myStats', JSON.stringify(stats));
       
       return stats;
    }
}

export default withTranslation()(Wordle);