import React, { useState } from 'react';
import i18n from '../../i18n';
import './languageSelector.css';

function LanguageSelector() {
  const getLanguage = () => {
    return i18n.language ||
      (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
      'en';
  };
  // State to store the selected language
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguage);



  // Function to handle language selection
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div>
      <select value={selectedLanguage} onChange={handleLanguageChange}>
        <option value="en">English (en)</option>
        <option value="es">Spanish (es)</option>
        <option value="fr">French (fr)</option>
      </select>
      {/* You can use the selectedLanguage in your app to control the language settings */}
    </div>
  );
}

export default LanguageSelector;
