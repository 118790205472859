import logo from './logo.svg';
import './App.css';
import WordleScreen from './screens/wordleScreen';
import WordsService from './services/wordsServiceLocal';
import { useEffect, useState } from 'react';

function App() {
  const service = new WordsService();
  const [word, setWord] = useState(null);

  useEffect(() => {
    service.getWordOfTheDay((data) => {
      setWord(data);
    });
  }, []);

  /*
  {
    "word":"data.word",
    "hintIconName": "data.hintIconName",
    "translation": 
       {    
          "es": "data.spanish",    
          "en": "data.english",    
          "fr": "data.french"  
       }
 };
  */

  return ( <div>
    {word ? <WordleScreen word={word} attempts="6"></WordleScreen> 
    : <div>Loading...</div>}
  
    </div>);
}

export default App;
